import $ from 'jquery';
/* eslint-disable */
/*!
 * cf.mori
 * fullcalendar用ファンクション Ver1.0
 *
 * jquery,fullcalendar,moment,moment-range 等必要なものは読み込み済であること
 *
 *　編集ログ==========================
 *	2019/07/**　*****：〇〇部を編集
 *
 *
 *	=================================
 */

/*-----------------------------------------------------
	クリック対象セルがルールを持っている場合の処理
--------------------------------------------------------*/
function dayClickRuleProc(date, jsEvent, view ,target){	//
	//▼　クリックされた日のイベントを扱う
    $(target).fullCalendar("clientEvents", function (e) {      
        if (moment(date).format("YYYY-MM-DD") === moment(e.start).format("YYYY-MM-DD")) {
            var target_repeat = e.className;
            var rule_id;
            if (target_repeat.indexOf("repeat") >= 0){  //class内にrepeat：繰り返しルールが存在するか
              rule_id = target_repeat[1].replace('rid_','');
              $('#leave_rule').parents('.form_outwrap').css("display","block");
              $('#leave_rule').val(rule_id);
            }
            else{
                $('#leave_rule').parents('.form_outwrap').css("display","none");  
            }
        }
    });
}


/*-----------------------------------------------------
	モーダル内inputへのデータ渡し・書き出し
--------------------------------------------------------*/
function modalMod(event,eTitle){	//クリック対象セルがルールを持っている場合の処理
	$('.title').each(function(){
        if($(this).val() == eTitle){
            $(this).prop('checked','true');
        }
    });
    $('#start_time').val(moment(event._d).format('YYYY-MM-DD'));
    $('#start_time_in').text(moment(event._d).format('YYYY-MM-DD'));
    $('#finish_time').val(moment(event.end).format('YYYY-MM-DD'));
    $('#title').val(event.title);
    $('#body').val(event.body);
    $('#event_id').val(event.id);
}
//▼イベントクリックは便宜的に下記を使用
function modalModevent(event,eTitle){    //クリック対象セルがルールを持っている場合の処理
    $('.title').each(function(){
        if($(this).val() == eTitle){
            $(this).prop('checked','true');
        }
    });
    $('#start_time').val(moment(event._start).format('YYYY-MM-DD'));
    $('#start_time_in').text(moment(event._start).format('YYYY-MM-DD'));
    $('#finish_time').val(moment(event.end).format('YYYY-MM-DD'));
    $('#title').val(event.title);
    $('#body').val(event.body);
    $('#event_id').val(event.id);
}


/*-----------------------------------------------------
	繰り返し（schedule_rule）の場合の書き出し判定
--------------------------------------------------------*/
function ruleJudge(events, element){
	//書き出す日付
    var thisDay = moment(events.start._d);
    var eventPeriodType = events.period_type;

    /*繰り返し週(週数指定)
    -------------------------*/
    if ( events.period_type.match(/th_week/)){
        var week =events.period_type.replace('th_week','');     //データの繰り返し設定週を取得
        var n = Math.floor((thisDay.date() - thisDay.day() + 12) / 7);     //現在日付が第何週かを計算

        if(week != n){  //対応する週数でない場合は書き出し中止返り値としてfalse
            return false;
        }
    }

    /*繰り返し週(第〇曜日指定)
    -------------------------*/
    if ( events.period_type.match(/th_dayof/)){
        var week =events.period_type.replace('th_dayof','');     //データの繰り返し設定週を取得
        var n =　Math.floor((events.start._d.getDate() - 1) / 7) + 1;     //現在日付が第何曜日かを計算

        console.log('week='+week,'n='+n);
        if(week != n){  //対応する週数でない場合は書き出し中止返り値としてfalse
            return false;
        }
    }
    
    /*期間（除外日から）
    -------------------------*/
    $flag = false;
    for(var i = 0, len = events.ranges.length; i < len; ++i){
        //console.log(events.ranges[i].start, events.ranges[i].end);
        if(thisDay.isBetween(events.ranges[i].start, events.ranges[i].end)){
            $flag = true;
        }        
    }
    if(!$flag){ //書き出し中止返り値としてfalse
        return false;
    }
}

/*-----------------------------------------------------
    イベントによるバックグラウンド判定
--------------------------------------------------------*/
function backgroundrule(events, element){
    if (events.title == "定休日") {
          var dataToFind = moment(events.start).format('YYYY-MM-DD');
        $("td[data-date='"+dataToFind+"']").addClass('status_teikyu');
    }
    if (events.title == "臨時休業日") {
          var dataToFind = moment(events.start).format('YYYY-MM-DD');
        $("td[data-date='"+dataToFind+"']").addClass('status_rinji');
    }
    if (events.title == "特別営業日") {
          var dataToFind = moment(events.start).format('YYYY-MM-DD');
        $("td[data-date='"+dataToFind+"']").addClass('status_tokubetsu');
    }
    if (events.title == "無料公開日") {
          var dataToFind = moment(events.start).format('YYYY-MM-DD');
        $("td[data-date='"+dataToFind+"']").addClass('status_muryou');
    }
    if (events.title == "お知らせあり") {
          var dataToFind = moment(events.start).format('YYYY-MM-DD');
          element.addClass('status_oshirase');
          element.addClass('pop-modal');
        element.attr("href", "#inline-wrapcln");
            //element.find('.fc-title').text("");
          element.find('.fc-content').prepend("<span class='explanation'></span>");
        //$("td[data-date='"+dataToFind+"']").addClass('status_oshirase');
    }                  
}    

    $(document).on("click", ".pop-modal", function(e){
        // prevent default action
        e.preventDefault();
        $(this).magnificPopup({
        type: 'inline',
        preloader: false, 
        closeOnContentClick: true,
        closeBtnInside: true,
        removalDelay: 300,
        gallery: { 
        //enabled: true,  
        },
        callbacks: {
        beforeOpen: function() {
        this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
        this.st.mainClass = this.st.el.attr('data-effect');
        }
        },  
        }).magnificPopup('open');
    });

/*-----------------------------------------------------
	モーダルの表示/非表示切替
--------------------------------------------------------*/
function ajaxSubmit(){

}




/*-----------------------------------------------------
	モーダルの表示/非表示切替
--------------------------------------------------------*/

//▼非表示処理
function modalHide(target){
    $(target).removeClass("open");
    $('#leave_rule').parents('.form_outwrap').css("display","none"); 
}


//▼表示-＞非表示処理
function modalToggle(target){
    $("#txtbody").hide();
    $(target).addClass("open");
   if ($( ".news_chk" ).prop( 'checked' ) ) {
    $("#txtbody").show();
   }
}



/*-----------------------------------------------------
    フロントトップ：カレンダーイベントクリック
--------------------------------------------------------*/
function eventClick(calEvent){

    var beforeDate = moment(calEvent.start._d,"YYYY-MM-DD");
    $('#information_date').html(beforeDate.format('YYYY年MM月DD日'));
    $("#information_date").attr('datetime', beforeDate.format('YYYY-MM-DD'));
    $('#information_body').html(calEvent.body);
    popbind();

}




