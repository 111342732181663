<template>
    <div class="animation-wrap">
        <div id="animation-shadow"><img src="@/assets/front_component/images/assets/meguru/05.png" alt="影"></div>
        <div id="animation-container">
            <router-link :to="{ name: 'Front archive', params: { type: 'mysdgs' } }">            
                <div class="animation-meguru">
                    <img src="@/assets/front_component/images/assets/meguru/01.png" alt="めぐる君01" class="animation-frame">
                    <img src="@/assets/front_component/images/assets/meguru/02.png" alt="めぐる君02" class="animation-frame">
                    <img src="@/assets/front_component/images/assets/meguru/03.png" alt="めぐる君03" class="animation-frame">
                    <img src="@/assets/front_component/images/assets/meguru/04.png" alt="めぐる君04" class="animation-frame">
                </div>
            </router-link>
            <router-link :to="{ name: 'Front archive', params: { type: 'mysdgs' } }" class="animation-flag">
                <div class="animation-flag__wrap">
                    <div class="animation-flag__left">
                        <span class="en">SDGs</span><span>目標</span>
                    </div>
                    <div class="animation-flag__right">
                        <span class="en">{{ longTermReportCount }}</span>
                        <span class="en fs-sm">/</span>
                        <span class="en">{{ longTermCount }}</span>
                        <span>達成！</span>
                    </div>
                </div>
            </router-link>
            <!-- <div class="animation-flag">
                <div class="animation-flag__wrap">
                    <div class="animation-flag__left">
                        <span class="en">SDGs</span><span>目標</span>
                    </div>
                    <div class="animation-flag__right">
                        <span class="en">{{ longTermReportCount }}</span>
                        <span class="en fs-sm">/</span>
                        <span class="en">{{ longTermCount }}</span>
                        <span>達成！</span>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import SDGs from "@/apis/SDGs";

export default {
    data(){
        return{
            longTermCount: 0,
            longTermReportCount: 0
        }
    },
    mounted(){
        
        SDGs.sdgsRatio().then((response)=>{
            let responseData = response.data;

			if (responseData) {
				this.longTermCount = new Intl.NumberFormat().format(responseData.long_term_count);
				this.longTermReportCount = new Intl.NumberFormat().format(responseData.long_term_sdgs_report_count);

                this.displayMeguruAnimation();
			}
        }).catch((err)=>{
            console.log(err);
			this.$router.push({ name: 'Front Error' });
        })
    },
    methods:{
        goToSdgsReport(){
            this.$router.push({ name: 'Control message', params: { type: 'mysdgs' } });
        },
        displayMeguruAnimation(){
            $(function(){
                $('#js-loader').delay(300).fadeOut(600, function() {
                    // .animation-flag__left と .animation-flag__right の横幅を計算
                    var leftWidth = $('.animation-flag__left').outerWidth();
                    var rightWidth = $('.animation-flag__right').outerWidth();
                    var totalWidth = leftWidth + rightWidth;

                    // 合計横幅を97の倍数に調整
                    var adjustedWidth = Math.floor(totalWidth / 97) * 97;

                    // 97の倍数から30を引いた値以下の場合は低い方に、それ以外は高い方に合わせる
                    if (totalWidth - adjustedWidth > 60) {
                        adjustedWidth += 97;
                    }

                    // 調整された横幅を .animation-flag__wrap に適用
                    $('.animation-flag__wrap').width(adjustedWidth);

                    function runAnimation() {	    
                        // 画面の幅と追加するピクセル値を計算
                        var windowWidth = $(window).width();
                        var endPos = windowWidth + adjustedWidth + 245; // 720pxを追加

                        // 右端からスタートさせるために初期位置を設定
                        $('#animation-container').css('right', '-120px');
                        $('#animation-shadow').css('right', '-120px');

                        // 右から左への移動アニメーション
                        $('#animation-shadow').animate({ right: endPos + 'px' }, 20000, 'linear');
                        $('#animation-container').animate({ right: endPos + 'px' }, 20000, 'linear', function() {
                        var totalEndPosValue =  endPos + 'px';
                          if ($('#animation-container').css('right') === totalEndPosValue) {
                            // 反転しているかどうかを確認
                            var isFlipped = $('.animation-wrap').hasClass('inversion');
                            // 反転させるかどうかのチェック
                            if (isFlipped) {
                                // 完全に右に出た場合（反転している状態で）、.inversion クラスを削除
                                $('.animation-wrap').removeClass('inversion');
                            }else{
                                // 完全に左に出た場合（反転していない状態で）、.inversion クラスを追加
                                $('.animation-wrap').addClass('inversion');
                            }


                            // アニメーションが完了したら、再びアニメーションを開始
                            runAnimation();
                          }                            
                        });
                    }

                    // アニメーションを開始
                    runAnimation();
                });

                var checkInterval = 1000; // ミリ秒
                var windowDivisions = 5; // ウィンドウの分割数

                function updateEyePosition() {
                    var windowWidth = $(window).width();
                    var containerCenter = $('#animation-container').offset().left + $('#animation-container').outerWidth() / 2;
                    var divisionWidth = windowWidth / windowDivisions;
                    var divisionIndex = Math.floor(containerCenter / divisionWidth);
                    var eyeLeft;

                    // divisionIndex に基づいてeyeLeftを計算
                    if (divisionIndex <= 0) {
                        eyeLeft = -5;
                    } else if (divisionIndex >= windowDivisions - 1) {
                        eyeLeft = 5;
                    } else {
                        // 中央からの距離に基づいて目の位置を計算
                        var centerIndex = (windowDivisions - 1) / 2;
                        eyeLeft = (divisionIndex - centerIndex) * (10 / (windowDivisions - 1));
                    }

                    // 目の位置を更新
                    $('.lefteye img, .righteye img').css('left', eyeLeft + 'px');
                }

                // インターバルで目の位置を更新
                setInterval(updateEyePosition, checkInterval);

                // ウィンドウのリサイズ時にも目の位置を更新
                $(window).resize(updateEyePosition);

                // インターバルで目の位置を更新
                setInterval(updateEyePosition, checkInterval);

                // ウィンドウのリサイズ時にも目の位置を更新
                $(window).resize(updateEyePosition);

            });
        }
    }
}
</script>

<style scoped>
@import "../../assets/front_component/scss/style-pc.css";
@import "../../assets/front_component/scss/style-top.css";
a:hover{
    opacity: 1 !important;
}
</style>